@import "_variables";

.mb15 {
  margin-bottom: 15px !important; }
.mt30 {
  margin-top: 30px !important; }
.img-full {
  width: 100%;
  display: block; }
.font-ttl {
  font-family: $font-ttl; }
.font-subtxt {
  font-family: $font-subtxt; }
.under-line {
  text-decoration: underline; }
.tac {
  text-align: center; }
.tal {
  text-align: left; }
.tar {
  text-align: right; }
