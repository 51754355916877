html {
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }
body {
  margin: 0;
  padding: 0; }
div,
p,
applet,
object,
fieldset {
  margin: 0;
  padding: 0;
  vertical-align: baseline; }
iframe {
  margin: 0;
  padding: 0;
  border: 0; }
a:focus {
  outline: thin dotted; }
a:hover,
a:active {
  outline: 0; }
b,
em,
strong {
  font-weight: bold; }
blockquote {
  margin: 1em 40px; }
small {
  font-size: 78%; }
ul,
ol {
  margin: 0;
  padding: 0; }
dl {
  margin: 0;
  padding: 0; }
dd {
  margin: 0;
  padding: 0 0 0 40px; }
li {
  list-style: none; }
img {
  border: 0;
  -ms-interpolation-mode: bicubic; }
svg:not(:root) {
  overflow: hidden; }
figure {
  margin: 0; }
form {
  margin: 0; }
fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }
button,
input,
select,
textarea {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }
button,
input {
  line-height: normal;
  *overflow: visible; }
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: none;
  cursor: pointer;
  -webkit-appearance: button;
  outline: none;
  box-shadow: none; }
button:focus {
  outline: none;
  box-shadow: none; }
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; }
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }
textarea {
  overflow: auto;
  vertical-align: top; }
table {
  border-collapse: collapse;
  border-spacing: 0; }
th,
td {
  margin: 0;
  padding: 0; }
h1,
h2,
h3,
h4,
h5,
h6,
th,
caption,
address {
  margin: 0;
  padding: 0;
  vertical-align: baseline; }
h1 {
  font-size: 200%; }
h2 {
  font-size: 175%; }
h3 {
  font-size: 150%; }
h4 {
  font-size: 125%; }
h5 {
  font-size: 110%; }
h6 {
  font-size: 100%; }
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0; }
a {
  text-decoration: none; }
a:focus {
  outline: none; }
.clearfix::after {
 content: "";
 display: table;
 clear: both; }
input,
textarea,
select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  max-width: 100%;
  border: 0;
  border-radius: 0;
  &:focus {
    outline: none;
    border: 0; } }

input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  &::-webkit-search-decoration {
    display: none; }
  &:focus {
    outline-offset: -2px; } }
