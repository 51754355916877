@import "_variables";

/* 全体共通 */
body {
  background: url('../images/bg.jpg');
  color: $txt-main;
  font-family: YakuHanJP, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased; }
.main {
  padding: 22px 0 39px;
  &#top {
    padding: 32px 0 49px; } }
.content {
  margin: 0 15px; }
.visible-pc {
  display: none !important; }
.visible-sp {
  display: block !important; }
::selection {
  background: #fff;
  color: #000; }
::-moz-selection {
  background: #fff;
  color: #000; }
@media screen and (min-width: $breakpoint) {
  .main {
    &#top {
      padding: 54px 0 173px; }
    &#access {
      padding: 70px 0 102px; }
    &#reserve {
      padding: 58px 0 86px; }
    &#course {
      padding: 70px 0 86px; }
    &#drink {
      padding: 57px 0 86px; }
    &#menu {
      padding: 58px 0 102px; }
    &#privacy {
      padding: 85px 0 170px; } }
  .content {
    width: calc(100% - 30px);
    max-width: 1020px;
    margin: 0 auto;
    #reserve & {
      max-width: 700px; }
    #course & {
      max-width: 1000px; }
    #drink & {
      max-width: 1000px; }
    #menu & {
      max-width: 990px; }
    #privacy & {
      max-width: 700px; } }
  .visible-pc {
    display: block !important; }
  .visible-sp {
    display: none !important; }
  .hover-link:hover {
    transition: opacity 0.3s;
    opacity: 0.7; } }

/* ヘッダー */
.g-header {
  width: 100%;
  padding: 19px;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  &__logo {
    width: 73px;
    margin: auto; }
  &__btn {
    width: 17px;
    height: 18px;
    position: fixed;
    top: 25px;
    right: 23px;
    z-index: 10;
    mix-blend-mode: exclusion;
    i {
      width: 100%;
      height: 2px;
      background: #fff;
      position: absolute;
      left: 0;
      &:nth-child(1) {
        top: 0; }
      &:nth-child(2) {
        top: 8px; }
      &:nth-child(3) {
        top: 16px; } } }
  &__block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; } }
@media screen and (min-width: $breakpoint) {
  .g-header {
    padding: 38px 19px 42px;
    .main_visual & {
      padding: 50px 0; }
    &__lead {
      font-size: 13px;
      text-align: left;
      .main_visual & {
        display: none !important; } }
    &__logo {
      width: 125px;
      margin: 0;
      .main_visual & {
        width: 116px;
        margin: 0 auto 26px; } }
    &_inner {
      max-width: 1044px;
      margin: 0 auto;
      position: relative;
      .main_visual & {
        max-width: none; } } }
  .g-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    .main_visual & {
      justify-content: center; }
    &_wrapper {
      .main_visual & {
        width: 100%; } }
    &__item {
      margin: 0 0 0 30px;
      color: $txt-main;
      font-size: 13px;
      font-weight: bold;
      .main_visual & {
        margin: 0 15px; }
      &--strong {
        .main_visual & {
          padding: 10px 36px;
          background: #ad1616;
          border-radius: 100px; } } } } }
@media screen and (min-width: $breakpoint-middle) {
  .g-nav__item {
    font-size: 16px; } }

/* フッター */
.g-footer {
  border-top: solid 1px #050505; }
.shop_informations {
  margin: 0 15px;
  padding: 20px 0; }
.shop_information {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0; }
  &__ttl {
    margin-bottom: 2px;
    color: #bcb8b8;
    font-size: 12px; }
  &__txt {
    color: #fff;
    font-size: 12px;
    a {
      color: #fff; } } }
.foot_area {
  padding: 26px 0 70px;
  border-top: solid 1px rgba(#fff, 0.13);
  text-align: center;
  &__ttl {
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: 600; }
  &__logo {
    width: 73px;
    margin-bottom: 35px; } }
.copyright_area {
  color: #4b4b4b;
  display: block;
  font-size: 10px;
  letter-spacing: 0.08em;
  .privacy-link {
    display: block;
    color: #888;
    margin-bottom: 10px; } }

.footer_nav {
  padding: 10px 0;
  background: $bg-main;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &__link {
    margin: 0 10px;
    color: #fff;
    font-size: 15px;
    font-weight: 700; } }
.sns_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  .g-header & {
    justify-content: flex-end; } }
.sns_icon {
  margin: 0 12px;
  line-height: 1;
  .g-header & {
    margin: 0 0 0 20px; }
  &--insta {
    width: 25px; }
  &--facebook {
    width: 13px; }
  &--twitter {
    width: 25px; } }
.prefecture {
  width: 100px;
  margin: 24px auto 52px;
  display: block; }
@media screen and (min-width: $breakpoint) {
  .g-footer {
    border-top: 0; }
  .footer_nav {
    padding: 21px 0;
    &__link {
      margin: 0 16px; } }
  .g-footer_content {
    width: calc(100% - 30px);
    max-width: 1000px;
    padding: 90px 0 10px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse; }
  .foot_area {
    width: 30.7%;
    padding: 7px 20px 5px 0;
    border-top: 0;
    border-right: solid 1px rgba(255,255,255,.13);
    text-align: left;
    &__ttl {
      font-size: 15px; }
    &__logo {
      width: 89px;
      margin-bottom: 47px; } }
  .shop_informations {
    width: 63.6%;
    margin: 0;
    padding: 9px 0; }
  .shop_information {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    &__ttl {
      width: 88px;
      margin-bottom: 0;
      font-size: 14px; }
    &__txt {
      flex: 1;
      font-size: 15px; } }
  .copyright_area {
    padding: 20px 0;
    margin-top: 60px;
    font-size: 12px;
    .privacy-link {
      display: inline-block;
      margin-right: 20px; } }

  .sns_icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .main_visual & {
      position: absolute;
      top: 27px;
      right: 50px; } }
  .sns_icon {
    margin: 0 20px 0 0; }
  .prefecture {
    width: 100px;
    margin: 36px auto 0 0; } }

/* メインビジュアル */
.main_visual {
  height: 495px;
  background: url('../images/bg_main.jpg') no-repeat center center;
  background-size: cover;
  &__ttl {
    margin: 85px 0 60px;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.7;
    text-align: center; }
  &__banner {
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  &__ubereats {
    width: 100px;
    display: block; }
  &__drink {
    width: 190px;
    display: block;
    margin-left: 10px; } }
@media screen and (min-width: $breakpoint) {
  .main_visual {
    height: 100vh;
    min-height: 700px;
    position: relative;
    &__ttl {
      position: absolute;
      top: 30vh;
      left: 0;
      right: 0;
      font-size: 53px; }
    &__banner {
      justify-content: flex-end;
      position: absolute;
      bottom: 46px;
      right: 4.1%; }
    &__ubereats {
      width: 200px; }
    &__drink {
      width: 380px;
      margin-left: 30px; } } }

/* 固定バー */
.fixed_bar {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  z-index: 11;
  &__head {
    padding: 3px 0;
    background: rgba(#1d1d1d, 0.5);
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    line-height: 1; }
  &__ballon {
    position: relative;
    padding: 5px 10px;
    background: $icon-red;
    border-radius: 100px;
    font-size: 10px;
    font-weight: 700;
    &:before {
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      border-style: solid;
      border-width: 5px 3px 0 3px;
      border-color: $icon-red transparent transparent transparent;
      content: "";
      display: inline-block;
      transform: translateX(-50%); } }
  &__txt {
    margin-left: 10px;
    font-size: 11px;
    line-height: 1.4;
    text-align: left;
    .cancell {
      text-decoration: line-through; }
    .yen {
      font-size: 10px; } }
  &__body {
    padding: 10px 0;
    background: #fff;
    color: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    line-height: 1; }
  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 3px; }
  &__tel {
    margin-right: 3px;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.01em; } }
@media screen and (min-width: $breakpoint) {
  .fixed_bar {
    padding: 20px 0;
    position: sticky;
    top: 0;
    bottom: auto;
    background: #fff;
    &__head {
      padding: 0;
      position: relative;
      left: -22px;
      background: #fff;
      color: #000;
      display: inline-flex;
      vertical-align: middle; }
    &__ballon {
      margin-right: 5px;
      padding: 5px 16px;
      color: #fff;
      font-size: 12px;
      &:before {
        display: none; } }
    &__txt {
      font-size: 14px;
      .strong {
        font-size: 15px;
        color: $txt-red; }
      .yen {
        font-size: 11px; } }
    &__body {
      padding: 0;
      display: inline-flex;
      vertical-align: middle; }
    &__icon {
      width: 18px;
      height: 18px;
      margin-right: 3px; }
    &__tel {
      margin-right: 12px;
      font-size: 26px;
      font-weight: 700;
      letter-spacing: 0.01em; } } }

/* ハンバーガーメニュー */
.menubar_wrapper {
  width: 100%;
  height: calc(100vh + 100px);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(#141414, 0.95);
  overflow: hidden;
  z-index: 99; }
.menubar {
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 69px 0 100px;
  position: relative;
  &__btn {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 25px;
    right: 16px;
    i {
      width: 15px;
      height: 2px;
      background: $txt-main;
      position: absolute; }
    i:nth-child(1) {
      top: 5px;
      left: -1px;
      transform: rotate(45deg); }
    i:nth-child(2) {
      bottom: 5px;
      left: -1px;
      transform: rotate(-45deg); } }
  &__item {
    margin-bottom: 26px;
    text-align: center; }
  &__link {
    color: $txt-main;
    font-size: 16px;
    &--strong {
      padding: 10px 36px;
      background: #ad1616;
      border-radius: 100px;
      display: inline-block; } }
  &__logo {
    width: 73px;
    margin: 0 auto 47px; } }
.menubar_inner {
  margin-bottom: 100px;
  .sns_icons {
    margin-top: 39px; } }

/* 各ページトップビジュアル */
.page_visual {
  height: 95px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &--access {
    background-image: url('../images/access/bg_page_visual.jpg'); }
  &--reserve {
    background-image: url('../images/reserve/bg_page_visual.jpg'); }
  &--course {
    background-image: url('../images/course/bg_page_visual.jpg'); }
  &--drink {
    background-image: url('../images/drink/bg_page_visual.jpg'); }
  &--menu {
    background-image: url('../images/menu/bg_page_visual.jpg'); }
  &--privacy {
    background-image: url('../images/privacy/bg_page_visual.jpg'); }
  &__ttl {
    font-size: 25px;
    font-weight: 600; } }
@media screen and (min-width: $breakpoint) {
  .page_visual {
    height: 191px;
    &__ttl {
      font-size: 40px; } } }

/* lightboxギャラリー */
.gallery {
  display: block;
  &__txt {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 700; } }
@media screen and (min-width: $breakpoint) {
  .galleries {
    display: flex;
    flex-wrap: wrap; }
  .gallery {
    &--2col {
      width: 50%; }
    &__txt {
      margin-top: 19px;
      font-size: 14px; } } }

/* サブタイトル */
.sub_ttl_wrapper {
  margin: 0 auto 15px;
  text-align: center; }
.sub_ttl {
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  &::before {
    width: 60px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: -17px;
    background: #404040;
    content: '';
    display: block;
    transform: translate3d(-100%, -50%, 0); }
  &::after {
    width: 60px;
    height: 1px;
    position: absolute;
    top: 50%;
    right: -17px;
    background: #404040;
    content: '';
    display: block;
    transform: translate3d(100%, -50%, 0); }
  &__price {
    display: block;
    font-size: 14px; } }
@media screen and (min-width: $breakpoint) {
  .sub_ttl_wrapper {
    margin: 0 auto 35px; }
  .sub_ttl {
    font-size: 25px;
    &::before {
      width: 88px; }
    &::after {
      width: 88px; }
    &__price {
      font-size: 18px; } } }

/* 飲み放題ブロック */
.drink_block {
  width: calc(100% - 30px);
  margin: 0 auto;
  display: block;
  #menu & {
    margin-top: 38px; } }
@media screen and (min-width: $breakpoint) {
  .drink_block {
    max-width: 770px;
    #menu & {
      margin-top: 70px; } } }

/* トップページ、カード */
.card-a {
  margin-bottom: 37px;
  &:last-child {
    margin-bottom: 0; }
  &__img {
    margin-bottom: 21px; }
  &__ttl {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600; }
  &__subttl {
    margin-bottom: 10px;
    color: #bc930c;
    font-size: 14px;
    font-weight: 600; }
  &__txt {
    margin-bottom: 21px;
    font-size: 12px;
    line-height: 1.86;
    .strong {
      font-weight: 700; } }
  &__btn_wrapper {
    text-align: center; }
  &__btn {
    margin: 0 auto;
    padding: 9px 44px;
    background: #fff;
    border-radius: 40px;
    color: $txt-sub;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    text-align: center; } }
@media screen and (min-width: $breakpoint) {
  .card-a {
    margin-bottom: 79px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &:nth-child(odd) {
      flex-direction: row-reverse; }
    &__img {
      width: 52%;
      margin-bottom: 0; }
    &__body {
      width: 43.4%;
      padding-top: 37px; }
    &__ttl {
      margin-bottom: 17px;
      font-size: 30px; }
    &__subttl {
      margin-bottom: 17px;
      font-size: 22px; }
    &__txt {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 1.86; }
    &__btn_wrapper {
      text-align: left; }
    &__btn {
      padding: 15px 72px;
      font-size: 19px; } } }

/* トップページ、instagram */
.instagram {
  margin-bottom: 48px;
  &__ttl {
    margin-bottom: 24px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.02em; }
  &__block {
    margin-bottom: 26px;
    display: block; }
  &__row {
    margin-bottom: 23px;
    display: flex;
    flex-wrap: wrap;
    &:last-child {
      margin-bottom: 0; } }
  &__img {
    width: 60px;
    height: 60px;
    object-fit: cover; }
  &__body {
    padding-left: 10px;
    flex: 1; }
  &__date {
    margin-bottom: 5px;
    color: #959595;
    font-size: 11px;
    line-height: 1; }
  &__txt {
    font-size: 12px;
    color: $txt-main;
    display: -webkit-box;
    line-height: 1.6;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; }
  &__btn {
    @extend .card-a__btn; } }
@media screen and (min-width: $breakpoint) {
  .instagram {
    max-width: 797px;
    margin: 0 auto 90px;
    &__ttl {
      margin-bottom: 43px;
      font-size: 22px;
      letter-spacing: 0;
      .strong {
        font-size: 30px; } }
    &__block {
      margin-bottom: 37px; }
    &__row {
      margin-bottom: 30px; }
    &__img {
      width: 100px;
      height: 100px; }
    &__body {
      padding: 10px 0 0 31px; }
    &__date {
      margin-bottom: 8px;
      font-size: 16px; }
    &__txt {
      font-size: 16px;
      line-height: 1.7;
      -webkit-line-clamp: 2; } } }

/* アクセスページ 店舗情報 */
.shop_informations--access {
  margin: 0 0 20px 0;
  padding: 0; }
.shop_information_left {
  margin-bottom: 15px; }
@media screen and (min-width: $breakpoint) {
  .shop_informations--access {
    width: 100%;
    margin-bottom: 54px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .shop_information_left {
    width: 44.9%;
    margin-bottom: 0;
    padding-right: 20px; }
  .shop_information_right {
    flex: 1; } }

/* googlemap */
.map_wrapper {
  margin-bottom: 30px; }
.map {
  width: 100%;
  height: 94vw; }
@media screen and (min-width: $breakpoint) {
  .map_wrapper {
    margin-bottom: 123px; }
  .map {
    height: 450px; } }

/* 予約ページ 予約フォーム */
.reserve {
  margin-bottom: 69px;
  &__row {
    margin-top: 22px;
    &:first-child {
      margin-top: 0; } }
  &__head {
    margin-bottom: 9px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start; }
  &__error {
    width: 100%;
    margin-top: 3px;
    color: #dd0a0a;
    font-size: 12px; }
  &__caution {
    width: 100%;
    margin-top: 3px;
    font-size: 12px; }
  &__ttl {
    font-size: 13px;
    font-weight: 700;
    .weak {
      font-size: 11px; } }
  &__label {
    margin-left: 7px;
    padding: 4px 7px;
    background: #222;
    font-size: 10px;
    &--require {
      background: $bg-require; } }
  &__input_text {
    width: 100%;
    min-height: 38px;
    padding: 9px 10px;
    background: $bg-form;
    color: $txt-main;
    font-size: 16px;
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: #505050; } }
  &__select_wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1;
    &::before {
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      left: 53px;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: $txt-main transparent transparent transparent;
      content: "";
      display: inline-block;
      transform: translateY(-50%); } }
  &__select {
    width: 73px;
    height: 40px;
    padding: 0 1em;
    background: $bg-form;
    color: $txt-main;
    cursor: pointer;
    font-size: 16px;
    &::-ms-expand {
      display: none; } }
  &__unit {
    padding-left: 12px;
    font-size: 13px; }
  &__radio {
    margin-bottom: 9px;
    position: relative;
    &:last-child {
      margin-bottom: 0; }
    input[type=radio] {
      // display: none
      visibility: hidden;
      position: absolute; }
    input:checked {
      & + label {
        border: solid 1px $txt-main;
        &::before {
        	border-color: #3b3b3b;
        	background: #fff; } } }
    label {
      padding: 10px 39px;
      position: relative;
      border: solid 1px $bg-form;
      border-radius: 3px;
      cursor: pointer;
      display: block;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .weak {
        padding-left: 8px;
        font-size: 12px; }
      &::before {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 10px;
        background: #3b3b3b;
        border: 5px solid #3b3b3b;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        transform: translateY(-50%); } } }
  &__textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    background: $bg-form;
    color: $txt-main;
    font-size: 16px; }
  &__policy {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
  &__checkbox_wrapper {
    position: relative;
    cursor: pointer; }
  &__checkbox {
    // display: none
    visibility: hidden;
    position: absolute;
    &_parts {
      padding-left: 20px;
      position: relative;
      font-size: 12px;
      &::before {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 0;
        background: #3b3b3b;
        content: '';
        display: block;
        transform: translateY(-50%); } }
    &:checked + .reserve__checkbox_parts::after {
      width: 7px;
      height: 14px;
      position: absolute;
      top: calc(50% - 4px);
      left: 3px;
      border-bottom: 4px solid $bg-require;
      border-right: 4px solid $bg-require;
      content: '';
      display: block;
      transform: translateY(-50%)rotate(40deg); } }
  &__submit {
    width: 100%;
    margin-top: 19px;
    padding: 13px 0;
    background: #8b6e2d;
    border-radius: 100px;
    color: $txt-main;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    &:focus {
      outline: none; } } }
#policy-error {
  text-align: center; }
@media screen and (min-width: $breakpoint) {
  .reserve {
    margin-bottom: 118px;
    padding: 0 37px;
    &__row {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between; }
    &__head {
      width: 197px;
      padding: 20px 25px 0 0;
      box-sizing: border-box;
      justify-content: space-between; }
    &__body {
      flex: 1; }
    &__ttl {
      font-size: 14px;
      .weak {
        font-size: 12px; } }
    &__label {
      padding: 3px 11px; }
    &__input_text {
      padding: 19px 29px;
      font-size: 16px; }
    &__select_wrapper {
      &::before {
        left: 62px; } }
    &__select {
      width: 86px;
      height: 60px; }
    &__unit {
      font-size: 16px; }
    &__radio {
      margin-bottom: 2px;
      label {
        padding: 10px 29px 10px 43px;
        display: inline-block;
        font-size: 16px;
        &::before {
          left: 14px; } } }
    &__textarea {
      height: 133px;
      resize: vertical; }
    &__checkbox {
      &_parts {
        padding-left: 32px;
        font-size: 14px;
        &::before {
          width: 18px;
          height: 18px; } }
      &:checked + .reserve__checkbox_parts::after {
        top: calc(50% - 2px);
        left: 4px; } }
    &__submit {
      width: 297px;
      margin: 46px auto 0;
      padding: 17px 0;
      display: block;
      font-size: 18px; } } }

/* リードテキスト */
.lead_txt {
  margin-bottom: 25px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.7;
  text-align: center; }
@media screen and (min-width: $breakpoint) {
  .lead_txt {
    margin-bottom: 65px;
    font-size: 16px; } }

/* 名物コースページ */
.card-b {
  margin-bottom: 29px;
  &__img {
    margin-bottom: 14px; }
  &__txt {
    font-size: 12px;
    .under-line {
      color: #fff; } } }
@media screen and (min-width: $breakpoint) {
  .card-b {
    margin-bottom: 81px;
    &__txt {
      font-size: 16px;
      text-align: right; } } }
.card-c {
  margin-bottom: 30px;
  &__imgs {
    margin-bottom: 9px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  &__img {
    width: 50%; }
  &__txt {
    font-size: 12px;
    line-height: 1.85; } }
@media screen and (min-width: $breakpoint) {
  .card-c {
    margin-bottom: 100px;
    &__imgs {
      margin-bottom: 29px; }
    &__img {
      width: 40.5%; }
    &__txt {
      font-size: 16px; } } }
.card-reserve {
  margin-bottom: 40px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  &__txt {
    text-align: center; }
  &__ttl {
    display: inline-block;
    padding: 4px 30px 3px;
    margin: 0 0 10px;
    background-color: $icon-red;
    border-radius: 20px;
    font-size: 14px; }
  .cancell {
    text-decoration: line-through; }
  .yen {
    font-size: 12px; } }

@media screen and (min-width: $breakpoint) {
  .card-reserve {
    margin-bottom: 50px;
    font-size: 18px;
    &__txt {
      text-align: center; }
    &__ttl {
      font-size: 14px; }
    .yen {
      font-size: 12px; } } }

/* ドリンクメニューページ */
.anchor_links {
  margin-bottom: 23px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
.anchor_link {
  margin: 0 7px 11px;
  color: #ffeded;
  font-size: 13px;
  font-weight: 600; }
.drink_menu {
  margin-bottom: 43px;
  &:last-child {
    margin-bottom: 0; } }
@media screen and (min-width: $breakpoint) {
  .anchor_links {
    margin-bottom: 125px; }
  .anchor_link {
    margin: 0 24px;
    font-size: 16px; }
  .drink_menu {
    margin-bottom: 60px; } }

/* ドリンクメニューページ */
.cards-d {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
.card-d {
  width: calc(50% - 7px);
  margin-bottom: 30px;
  &__name {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 700; }
  &__price {
    margin-top: 3px;
    font-size: 12px; }
  &__empty {
    height: 0; } }
.menu {
  &__block {
    margin-bottom: 65px; }
  &__item {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    a {
      color: $txt-main; }
    &:last-child {
      margin-bottom: 0; } }
  &__price {
    padding-left: 6px;
    font-size: 12px;
    font-weight: 500; } }
@media screen and (min-width: $breakpoint) {
  .cards-d {
    justify-content: space-between; }
  .card-d {
    width: calc((100% - 90px)/4);
    margin-bottom: 55px;
    &__name {
      margin-top: 18px;
      font-size: 18px; }
    &__price {
      margin-top: 8px;
      font-size: 16px; }
    &__empty {
      height: 0; } }
  .menu {
    &__row {
      margin-bottom: 88px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    &__block {
      width: 50%;
      margin-bottom: 0;
      padding-right: 5%;
      box-sizing: border-box; }
    &__item {
      margin-bottom: 23px;
      font-size: 18px;
      text-align: center;
      &:last-child {
        margin-bottom: 0; } }
    &__price {
      padding-left: 14px;
      font-size: 16px;
      font-weight: 500; } } }

/* プライバシーポリシーページ */
.privacy {
  &__block {
    margin-bottom: 29px;
    &--last {
      margin-bottom: 15px; } }
  &__ttl {
    margin-bottom: 11px;
    font-size: 17px;
    font-weight: 600; }
  &__txt {
    font-size: 13px;
    line-height: 1.7;
    a {
      color: $txt-main; } }
  &__foot {
    font-size: 13px; } }
.information_box {
  margin-bottom: 15px;
  padding: 15px 18px;
  background: #292929;
  font-size: 13px;
  line-height: 1.7;
  .strong {
    font-weight: 700; }
  a {
    color: $txt-main; } }
@media screen and (min-width: $breakpoint) {
  .privacy {
    &__block {
      margin-bottom: 57px;
      &--last {
        margin-bottom: 20px; } }
    &__ttl {
      margin-bottom: 24px;
      font-size: 25px; }
    &__txt {
      font-size: 16px; }
    &__foot {
      font-size: 16px; } }
  .information_box {
    margin-bottom: 40px;
    padding: 23px 32px;
    font-size: 16px; } }

/* THANKSページ */
.thanks {
  &__block {
    max-width: 800px;
    margin: 30px auto;
    text-align: center; }
  &__ttl {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600; }
  &__txt {
    display: table;
    font-size: 12px;
    margin: 0 auto 20px;
    line-height: 1.7; }
  &__txt2 {
    padding: 20px;
    font-size: 12px;
    margin: 0 auto 30px;
    line-height: 1.7;
    text-align: left;
    border: 1px solid #555; }
  &__btn {
    @extend .card-a__btn; } }
@media screen and (min-width: $breakpoint) {
  .thanks {
    &__block {
      margin: 100px auto; }
    &__ttl {
      margin-bottom: 40px;
      font-size: 32px; }
    &__txt {
      margin: 0 auto 40px;
      font-size: 16px;
      text-align: center; }
    &__txt2 {
      padding: 30px;
      margin: 0 auto 60px;
      font-size: 14px; } } }
