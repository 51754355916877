@import url('https://fonts.googleapis.com/css?family=Roboto:500,700&display=swap');

$yakuhanjp-eot-use: false;
$yakuhanjp-cdn-use: true;
@import "yakuhanjp/dist/scss/yakuhanjp";

@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";
@import "lightbox2/src/css/lightbox";
@import "global/normalize";
@import "utility";
@import "common";
