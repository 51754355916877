@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:500,700&display=swap");
/*! Yaku Han JP v3.4.1 (OFL-1.1 AND MIT) by Qrac */
/*! Type: YakuHanJP - Based on Noto Sans CJK JP */
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Thin.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Thin.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-DemiLight.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-DemiLight.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Black.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/fonts/YakuHanJP/YakuHanJP-Black.woff") format("woff");
  unicode-range: U+3001, U+3002, U+3008, U+3009, U+300a, U+300b, U+300c, U+300d, U+300e, U+300f, U+3010, U+3011, U+3014, U+3015, U+30fb, U+ff01, U+ff08, U+ff09, U+ff1a, U+ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

html {
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
}

div,
p,
applet,
object,
fieldset {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

b,
em,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

small {
  font-size: 78%;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
  padding: 0;
}

dd {
  margin: 0;
  padding: 0 0 0 40px;
}

li {
  list-style: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

button,
input,
select,
textarea {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: none;
  cursor: pointer;
  -webkit-appearance: button;
  outline: none;
  box-shadow: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
caption,
address {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

h1 {
  font-size: 200%;
}

h2 {
  font-size: 175%;
}

h3 {
  font-size: 150%;
}

h4 {
  font-size: 125%;
}

h5 {
  font-size: 110%;
}

h6 {
  font-size: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:focus {
  outline: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

input,
textarea,
select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  max-width: 100%;
  border: 0;
  border-radius: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: 0;
}

input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]:focus,
input[type="button"]:focus {
  outline-offset: -2px;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.img-full {
  width: 100%;
  display: block;
}

.font-ttl {
  font-family: YakuHanJP, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

.font-subtxt {
  font-family: YakuHanJP, "Roboto", sans-serif;
}

.under-line {
  text-decoration: underline;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

/* 全体共通 */
body {
  background: url("../images/bg.jpg");
  color: #fff;
  font-family: YakuHanJP, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

.main {
  padding: 22px 0 39px;
}

.main#top {
  padding: 32px 0 49px;
}

.content {
  margin: 0 15px;
}

.visible-pc {
  display: none !important;
}

.visible-sp {
  display: block !important;
}

::selection {
  background: #fff;
  color: #000;
}

::-moz-selection {
  background: #fff;
  color: #000;
}

@media screen and (min-width: 768px) {
  .main#top {
    padding: 54px 0 173px;
  }
  .main#access {
    padding: 70px 0 102px;
  }
  .main#reserve {
    padding: 58px 0 86px;
  }
  .main#course {
    padding: 70px 0 86px;
  }
  .main#drink {
    padding: 57px 0 86px;
  }
  .main#menu {
    padding: 58px 0 102px;
  }
  .main#privacy {
    padding: 85px 0 170px;
  }
  .content {
    width: calc(100% - 30px);
    max-width: 1020px;
    margin: 0 auto;
  }
  #reserve .content {
    max-width: 700px;
  }
  #course .content {
    max-width: 1000px;
  }
  #drink .content {
    max-width: 1000px;
  }
  #menu .content {
    max-width: 990px;
  }
  #privacy .content {
    max-width: 700px;
  }
  .visible-pc {
    display: block !important;
  }
  .visible-sp {
    display: none !important;
  }
  .hover-link:hover {
    transition: opacity 0.3s;
    opacity: 0.7;
  }
}

/* ヘッダー */
.g-header {
  width: 100%;
  padding: 19px;
  position: relative;
  box-sizing: border-box;
  text-align: center;
}

.g-header__logo {
  width: 73px;
  margin: auto;
}

.g-header__btn {
  width: 17px;
  height: 18px;
  position: fixed;
  top: 25px;
  right: 23px;
  z-index: 10;
  mix-blend-mode: exclusion;
}

.g-header__btn i {
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
}

.g-header__btn i:nth-child(1) {
  top: 0;
}

.g-header__btn i:nth-child(2) {
  top: 8px;
}

.g-header__btn i:nth-child(3) {
  top: 16px;
}

.g-header__block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .g-header {
    padding: 38px 19px 42px;
  }
  .main_visual .g-header {
    padding: 50px 0;
  }
  .g-header__lead {
    font-size: 13px;
    text-align: left;
  }
  .main_visual .g-header__lead {
    display: none !important;
  }
  .g-header__logo {
    width: 125px;
    margin: 0;
  }
  .main_visual .g-header__logo {
    width: 116px;
    margin: 0 auto 26px;
  }
  .g-header_inner {
    max-width: 1044px;
    margin: 0 auto;
    position: relative;
  }
  .main_visual .g-header_inner {
    max-width: none;
  }
  .g-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
  .main_visual .g-nav {
    justify-content: center;
  }
  .main_visual .g-nav_wrapper {
    width: 100%;
  }
  .g-nav__item {
    margin: 0 0 0 30px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
  }
  .main_visual .g-nav__item {
    margin: 0 15px;
  }
  .main_visual .g-nav__item--strong {
    padding: 10px 36px;
    background: #ad1616;
    border-radius: 100px;
  }
}

@media screen and (min-width: 852px) {
  .g-nav__item {
    font-size: 16px;
  }
}

/* フッター */
.g-footer {
  border-top: solid 1px #050505;
}

.shop_informations {
  margin: 0 15px;
  padding: 20px 0;
}

.shop_information {
  margin-bottom: 15px;
}

.shop_information:last-child {
  margin-bottom: 0;
}

.shop_information__ttl {
  margin-bottom: 2px;
  color: #bcb8b8;
  font-size: 12px;
}

.shop_information__txt {
  color: #fff;
  font-size: 12px;
}

.shop_information__txt a {
  color: #fff;
}

.foot_area {
  padding: 26px 0 70px;
  border-top: solid 1px rgba(255, 255, 255, 0.13);
  text-align: center;
}

.foot_area__ttl {
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 600;
}

.foot_area__logo {
  width: 73px;
  margin-bottom: 35px;
}

.copyright_area {
  color: #4b4b4b;
  display: block;
  font-size: 10px;
  letter-spacing: 0.08em;
}

.copyright_area .privacy-link {
  display: block;
  color: #888;
  margin-bottom: 10px;
}

.footer_nav {
  padding: 10px 0;
  background: #000;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footer_nav__link {
  margin: 0 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.sns_icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-header .sns_icons {
  justify-content: flex-end;
}

.sns_icon {
  margin: 0 12px;
  line-height: 1;
}

.g-header .sns_icon {
  margin: 0 0 0 20px;
}

.sns_icon--insta {
  width: 25px;
}

.sns_icon--facebook {
  width: 13px;
}

.sns_icon--twitter {
  width: 25px;
}

.prefecture {
  width: 100px;
  margin: 24px auto 52px;
  display: block;
}

@media screen and (min-width: 768px) {
  .g-footer {
    border-top: 0;
  }
  .footer_nav {
    padding: 21px 0;
  }
  .footer_nav__link {
    margin: 0 16px;
  }
  .g-footer_content {
    width: calc(100% - 30px);
    max-width: 1000px;
    padding: 90px 0 10px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .foot_area {
    width: 30.7%;
    padding: 7px 20px 5px 0;
    border-top: 0;
    border-right: solid 1px rgba(255, 255, 255, 0.13);
    text-align: left;
  }
  .foot_area__ttl {
    font-size: 15px;
  }
  .foot_area__logo {
    width: 89px;
    margin-bottom: 47px;
  }
  .shop_informations {
    width: 63.6%;
    margin: 0;
    padding: 9px 0;
  }
  .shop_information {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shop_information__ttl {
    width: 88px;
    margin-bottom: 0;
    font-size: 14px;
  }
  .shop_information__txt {
    flex: 1;
    font-size: 15px;
  }
  .copyright_area {
    padding: 20px 0;
    margin-top: 60px;
    font-size: 12px;
  }
  .copyright_area .privacy-link {
    display: inline-block;
    margin-right: 20px;
  }
  .sns_icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .main_visual .sns_icons {
    position: absolute;
    top: 27px;
    right: 50px;
  }
  .sns_icon {
    margin: 0 20px 0 0;
  }
  .prefecture {
    width: 100px;
    margin: 36px auto 0 0;
  }
}

/* メインビジュアル */
.main_visual {
  height: 495px;
  background: url("../images/bg_main.jpg") no-repeat center center;
  background-size: cover;
}

.main_visual__ttl {
  margin: 85px 0 60px;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.7;
  text-align: center;
}

.main_visual__banner {
  margin: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main_visual__ubereats {
  width: 100px;
  display: block;
}

.main_visual__drink {
  width: 190px;
  display: block;
  margin-left: 10px;
}

@media screen and (min-width: 768px) {
  .main_visual {
    height: 100vh;
    min-height: 700px;
    position: relative;
  }
  .main_visual__ttl {
    position: absolute;
    top: 30vh;
    left: 0;
    right: 0;
    font-size: 53px;
  }
  .main_visual__banner {
    justify-content: flex-end;
    position: absolute;
    bottom: 46px;
    right: 4.1%;
  }
  .main_visual__ubereats {
    width: 200px;
  }
  .main_visual__drink {
    width: 380px;
    margin-left: 30px;
  }
}

/* 固定バー */
.fixed_bar {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  z-index: 11;
}

.fixed_bar__head {
  padding: 3px 0;
  background: rgba(29, 29, 29, 0.5);
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.fixed_bar__ballon {
  position: relative;
  padding: 5px 10px;
  background: #a30909;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 700;
}

.fixed_bar__ballon:before {
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  border-style: solid;
  border-width: 5px 3px 0 3px;
  border-color: #a30909 transparent transparent transparent;
  content: "";
  display: inline-block;
  transform: translateX(-50%);
}

.fixed_bar__txt {
  margin-left: 10px;
  font-size: 11px;
  line-height: 1.4;
  text-align: left;
}

.fixed_bar__txt .cancell {
  text-decoration: line-through;
}

.fixed_bar__txt .yen {
  font-size: 10px;
}

.fixed_bar__body {
  padding: 10px 0;
  background: #fff;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.fixed_bar__icon {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.fixed_bar__tel {
  margin-right: 3px;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;
}

@media screen and (min-width: 768px) {
  .fixed_bar {
    padding: 20px 0;
    position: sticky;
    top: 0;
    bottom: auto;
    background: #fff;
  }
  .fixed_bar__head {
    padding: 0;
    position: relative;
    left: -22px;
    background: #fff;
    color: #000;
    display: inline-flex;
    vertical-align: middle;
  }
  .fixed_bar__ballon {
    margin-right: 5px;
    padding: 5px 16px;
    color: #fff;
    font-size: 12px;
  }
  .fixed_bar__ballon:before {
    display: none;
  }
  .fixed_bar__txt {
    font-size: 14px;
  }
  .fixed_bar__txt .strong {
    font-size: 15px;
    color: #db0b0b;
  }
  .fixed_bar__txt .yen {
    font-size: 11px;
  }
  .fixed_bar__body {
    padding: 0;
    display: inline-flex;
    vertical-align: middle;
  }
  .fixed_bar__icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
  .fixed_bar__tel {
    margin-right: 12px;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

/* ハンバーガーメニュー */
.menubar_wrapper {
  width: 100%;
  height: calc(100vh + 100px);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(20, 20, 20, 0.95);
  overflow: hidden;
  z-index: 99;
}

.menubar {
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 69px 0 100px;
  position: relative;
}

.menubar__btn {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 25px;
  right: 16px;
}

.menubar__btn i {
  width: 15px;
  height: 2px;
  background: #fff;
  position: absolute;
}

.menubar__btn i:nth-child(1) {
  top: 5px;
  left: -1px;
  transform: rotate(45deg);
}

.menubar__btn i:nth-child(2) {
  bottom: 5px;
  left: -1px;
  transform: rotate(-45deg);
}

.menubar__item {
  margin-bottom: 26px;
  text-align: center;
}

.menubar__link {
  color: #fff;
  font-size: 16px;
}

.menubar__link--strong {
  padding: 10px 36px;
  background: #ad1616;
  border-radius: 100px;
  display: inline-block;
}

.menubar__logo {
  width: 73px;
  margin: 0 auto 47px;
}

.menubar_inner {
  margin-bottom: 100px;
}

.menubar_inner .sns_icons {
  margin-top: 39px;
}

/* 各ページトップビジュアル */
.page_visual {
  height: 95px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.page_visual--access {
  background-image: url("../images/access/bg_page_visual.jpg");
}

.page_visual--reserve {
  background-image: url("../images/reserve/bg_page_visual.jpg");
}

.page_visual--course {
  background-image: url("../images/course/bg_page_visual.jpg");
}

.page_visual--drink {
  background-image: url("../images/drink/bg_page_visual.jpg");
}

.page_visual--menu {
  background-image: url("../images/menu/bg_page_visual.jpg");
}

.page_visual--privacy {
  background-image: url("../images/privacy/bg_page_visual.jpg");
}

.page_visual__ttl {
  font-size: 25px;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .page_visual {
    height: 191px;
  }
  .page_visual__ttl {
    font-size: 40px;
  }
}

/* lightboxギャラリー */
.gallery {
  display: block;
}

.gallery__txt {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .galleries {
    display: flex;
    flex-wrap: wrap;
  }
  .gallery--2col {
    width: 50%;
  }
  .gallery__txt {
    margin-top: 19px;
    font-size: 14px;
  }
}

/* サブタイトル */
.sub_ttl_wrapper {
  margin: 0 auto 15px;
  text-align: center;
}

.sub_ttl {
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
}

.sub_ttl::before {
  width: 60px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -17px;
  background: #404040;
  content: '';
  display: block;
  transform: translate3d(-100%, -50%, 0);
}

.sub_ttl::after {
  width: 60px;
  height: 1px;
  position: absolute;
  top: 50%;
  right: -17px;
  background: #404040;
  content: '';
  display: block;
  transform: translate3d(100%, -50%, 0);
}

.sub_ttl__price {
  display: block;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .sub_ttl_wrapper {
    margin: 0 auto 35px;
  }
  .sub_ttl {
    font-size: 25px;
  }
  .sub_ttl::before {
    width: 88px;
  }
  .sub_ttl::after {
    width: 88px;
  }
  .sub_ttl__price {
    font-size: 18px;
  }
}

/* 飲み放題ブロック */
.drink_block {
  width: calc(100% - 30px);
  margin: 0 auto;
  display: block;
}

#menu .drink_block {
  margin-top: 38px;
}

@media screen and (min-width: 768px) {
  .drink_block {
    max-width: 770px;
  }
  #menu .drink_block {
    margin-top: 70px;
  }
}

/* トップページ、カード */
.card-a {
  margin-bottom: 37px;
}

.card-a:last-child {
  margin-bottom: 0;
}

.card-a__img {
  margin-bottom: 21px;
}

.card-a__ttl {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.card-a__subttl {
  margin-bottom: 10px;
  color: #bc930c;
  font-size: 14px;
  font-weight: 600;
}

.card-a__txt {
  margin-bottom: 21px;
  font-size: 12px;
  line-height: 1.86;
}

.card-a__txt .strong {
  font-weight: 700;
}

.card-a__btn_wrapper {
  text-align: center;
}

.card-a__btn, .instagram__btn, .thanks__btn {
  margin: 0 auto;
  padding: 9px 44px;
  background: #fff;
  border-radius: 40px;
  color: #242424;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .card-a {
    margin-bottom: 79px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .card-a:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .card-a__img {
    width: 52%;
    margin-bottom: 0;
  }
  .card-a__body {
    width: 43.4%;
    padding-top: 37px;
  }
  .card-a__ttl {
    margin-bottom: 17px;
    font-size: 30px;
  }
  .card-a__subttl {
    margin-bottom: 17px;
    font-size: 22px;
  }
  .card-a__txt {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.86;
  }
  .card-a__btn_wrapper {
    text-align: left;
  }
  .card-a__btn, .instagram__btn, .thanks__btn {
    padding: 15px 72px;
    font-size: 19px;
  }
}

/* トップページ、instagram */
.instagram {
  margin-bottom: 48px;
}

.instagram__ttl {
  margin-bottom: 24px;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.instagram__block {
  margin-bottom: 26px;
  display: block;
}

.instagram__row {
  margin-bottom: 23px;
  display: flex;
  flex-wrap: wrap;
}

.instagram__row:last-child {
  margin-bottom: 0;
}

.instagram__img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.instagram__body {
  padding-left: 10px;
  flex: 1;
}

.instagram__date {
  margin-bottom: 5px;
  color: #959595;
  font-size: 11px;
  line-height: 1;
}

.instagram__txt {
  font-size: 12px;
  color: #fff;
  display: -webkit-box;
  line-height: 1.6;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media screen and (min-width: 768px) {
  .instagram {
    max-width: 797px;
    margin: 0 auto 90px;
  }
  .instagram__ttl {
    margin-bottom: 43px;
    font-size: 22px;
    letter-spacing: 0;
  }
  .instagram__ttl .strong {
    font-size: 30px;
  }
  .instagram__block {
    margin-bottom: 37px;
  }
  .instagram__row {
    margin-bottom: 30px;
  }
  .instagram__img {
    width: 100px;
    height: 100px;
  }
  .instagram__body {
    padding: 10px 0 0 31px;
  }
  .instagram__date {
    margin-bottom: 8px;
    font-size: 16px;
  }
  .instagram__txt {
    font-size: 16px;
    line-height: 1.7;
    -webkit-line-clamp: 2;
  }
}

/* アクセスページ 店舗情報 */
.shop_informations--access {
  margin: 0 0 20px 0;
  padding: 0;
}

.shop_information_left {
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .shop_informations--access {
    width: 100%;
    margin-bottom: 54px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .shop_information_left {
    width: 44.9%;
    margin-bottom: 0;
    padding-right: 20px;
  }
  .shop_information_right {
    flex: 1;
  }
}

/* googlemap */
.map_wrapper {
  margin-bottom: 30px;
}

.map {
  width: 100%;
  height: 94vw;
}

@media screen and (min-width: 768px) {
  .map_wrapper {
    margin-bottom: 123px;
  }
  .map {
    height: 450px;
  }
}

/* 予約ページ 予約フォーム */
.reserve {
  margin-bottom: 69px;
}

.reserve__row {
  margin-top: 22px;
}

.reserve__row:first-child {
  margin-top: 0;
}

.reserve__head {
  margin-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.reserve__error {
  width: 100%;
  margin-top: 3px;
  color: #dd0a0a;
  font-size: 12px;
}

.reserve__caution {
  width: 100%;
  margin-top: 3px;
  font-size: 12px;
}

.reserve__ttl {
  font-size: 13px;
  font-weight: 700;
}

.reserve__ttl .weak {
  font-size: 11px;
}

.reserve__label {
  margin-left: 7px;
  padding: 4px 7px;
  background: #222;
  font-size: 10px;
}

.reserve__label--require {
  background: #8b0606;
}

.reserve__input_text {
  width: 100%;
  min-height: 38px;
  padding: 9px 10px;
  background: #2f2f2f;
  color: #fff;
  font-size: 16px;
}

.reserve__input_text::placeholder, .reserve__input_text:-ms-input-placeholder, .reserve__input_text::-ms-input-placeholder {
  color: #505050;
}

.reserve__select_wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
}

.reserve__select_wrapper::before {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 53px;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #fff transparent transparent transparent;
  content: "";
  display: inline-block;
  transform: translateY(-50%);
}

.reserve__select {
  width: 73px;
  height: 40px;
  padding: 0 1em;
  background: #2f2f2f;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.reserve__select::-ms-expand {
  display: none;
}

.reserve__unit {
  padding-left: 12px;
  font-size: 13px;
}

.reserve__radio {
  margin-bottom: 9px;
  position: relative;
}

.reserve__radio:last-child {
  margin-bottom: 0;
}

.reserve__radio input[type=radio] {
  visibility: hidden;
  position: absolute;
}

.reserve__radio input:checked + label {
  border: solid 1px #fff;
}

.reserve__radio input:checked + label::before {
  border-color: #3b3b3b;
  background: #fff;
}

.reserve__radio label {
  padding: 10px 39px;
  position: relative;
  border: solid 1px #2f2f2f;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reserve__radio label .weak {
  padding-left: 8px;
  font-size: 12px;
}

.reserve__radio label::before {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 10px;
  background: #3b3b3b;
  border: 5px solid #3b3b3b;
  border-radius: 50%;
  box-sizing: border-box;
  content: '';
  transform: translateY(-50%);
}

.reserve__textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  background: #2f2f2f;
  color: #fff;
  font-size: 16px;
}

.reserve__policy {
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.reserve__checkbox_wrapper {
  position: relative;
  cursor: pointer;
}

.reserve__checkbox {
  visibility: hidden;
  position: absolute;
}

.reserve__checkbox_parts {
  padding-left: 20px;
  position: relative;
  font-size: 12px;
}

.reserve__checkbox_parts::before {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #3b3b3b;
  content: '';
  display: block;
  transform: translateY(-50%);
}

.reserve__checkbox:checked + .reserve__checkbox_parts::after {
  width: 7px;
  height: 14px;
  position: absolute;
  top: calc(50% - 4px);
  left: 3px;
  border-bottom: 4px solid #8b0606;
  border-right: 4px solid #8b0606;
  content: '';
  display: block;
  transform: translateY(-50%) rotate(40deg);
}

.reserve__submit {
  width: 100%;
  margin-top: 19px;
  padding: 13px 0;
  background: #8b6e2d;
  border-radius: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.reserve__submit:focus {
  outline: none;
}

#policy-error {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .reserve {
    margin-bottom: 118px;
    padding: 0 37px;
  }
  .reserve__row {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .reserve__head {
    width: 197px;
    padding: 20px 25px 0 0;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .reserve__body {
    flex: 1;
  }
  .reserve__ttl {
    font-size: 14px;
  }
  .reserve__ttl .weak {
    font-size: 12px;
  }
  .reserve__label {
    padding: 3px 11px;
  }
  .reserve__input_text {
    padding: 19px 29px;
    font-size: 16px;
  }
  .reserve__select_wrapper::before {
    left: 62px;
  }
  .reserve__select {
    width: 86px;
    height: 60px;
  }
  .reserve__unit {
    font-size: 16px;
  }
  .reserve__radio {
    margin-bottom: 2px;
  }
  .reserve__radio label {
    padding: 10px 29px 10px 43px;
    display: inline-block;
    font-size: 16px;
  }
  .reserve__radio label::before {
    left: 14px;
  }
  .reserve__textarea {
    height: 133px;
    resize: vertical;
  }
  .reserve__checkbox_parts {
    padding-left: 32px;
    font-size: 14px;
  }
  .reserve__checkbox_parts::before {
    width: 18px;
    height: 18px;
  }
  .reserve__checkbox:checked + .reserve__checkbox_parts::after {
    top: calc(50% - 2px);
    left: 4px;
  }
  .reserve__submit {
    width: 297px;
    margin: 46px auto 0;
    padding: 17px 0;
    display: block;
    font-size: 18px;
  }
}

/* リードテキスト */
.lead_txt {
  margin-bottom: 25px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.7;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .lead_txt {
    margin-bottom: 65px;
    font-size: 16px;
  }
}

/* 名物コースページ */
.card-b {
  margin-bottom: 29px;
}

.card-b__img {
  margin-bottom: 14px;
}

.card-b__txt {
  font-size: 12px;
}

.card-b__txt .under-line {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .card-b {
    margin-bottom: 81px;
  }
  .card-b__txt {
    font-size: 16px;
    text-align: right;
  }
}

.card-c {
  margin-bottom: 30px;
}

.card-c__imgs {
  margin-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-c__img {
  width: 50%;
}

.card-c__txt {
  font-size: 12px;
  line-height: 1.85;
}

@media screen and (min-width: 768px) {
  .card-c {
    margin-bottom: 100px;
  }
  .card-c__imgs {
    margin-bottom: 29px;
  }
  .card-c__img {
    width: 40.5%;
  }
  .card-c__txt {
    font-size: 16px;
  }
}

.card-reserve {
  margin-bottom: 40px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.card-reserve__txt {
  text-align: center;
}

.card-reserve__ttl {
  display: inline-block;
  padding: 4px 30px 3px;
  margin: 0 0 10px;
  background-color: #a30909;
  border-radius: 20px;
  font-size: 14px;
}

.card-reserve .cancell {
  text-decoration: line-through;
}

.card-reserve .yen {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .card-reserve {
    margin-bottom: 50px;
    font-size: 18px;
  }
  .card-reserve__txt {
    text-align: center;
  }
  .card-reserve__ttl {
    font-size: 14px;
  }
  .card-reserve .yen {
    font-size: 12px;
  }
}

/* ドリンクメニューページ */
.anchor_links {
  margin-bottom: 23px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.anchor_link {
  margin: 0 7px 11px;
  color: #ffeded;
  font-size: 13px;
  font-weight: 600;
}

.drink_menu {
  margin-bottom: 43px;
}

.drink_menu:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .anchor_links {
    margin-bottom: 125px;
  }
  .anchor_link {
    margin: 0 24px;
    font-size: 16px;
  }
  .drink_menu {
    margin-bottom: 60px;
  }
}

/* ドリンクメニューページ */
.cards-d {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-d {
  width: calc(50% - 7px);
  margin-bottom: 30px;
}

.card-d__name {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 700;
}

.card-d__price {
  margin-top: 3px;
  font-size: 12px;
}

.card-d__empty {
  height: 0;
}

.menu__block {
  margin-bottom: 65px;
}

.menu__item {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
}

.menu__item a {
  color: #fff;
}

.menu__item:last-child {
  margin-bottom: 0;
}

.menu__price {
  padding-left: 6px;
  font-size: 12px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .cards-d {
    justify-content: space-between;
  }
  .card-d {
    width: calc((100% - 90px)/4);
    margin-bottom: 55px;
  }
  .card-d__name {
    margin-top: 18px;
    font-size: 18px;
  }
  .card-d__price {
    margin-top: 8px;
    font-size: 16px;
  }
  .card-d__empty {
    height: 0;
  }
  .menu__row {
    margin-bottom: 88px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .menu__block {
    width: 50%;
    margin-bottom: 0;
    padding-right: 5%;
    box-sizing: border-box;
  }
  .menu__item {
    margin-bottom: 23px;
    font-size: 18px;
    text-align: center;
  }
  .menu__item:last-child {
    margin-bottom: 0;
  }
  .menu__price {
    padding-left: 14px;
    font-size: 16px;
    font-weight: 500;
  }
}

/* プライバシーポリシーページ */
.privacy__block {
  margin-bottom: 29px;
}

.privacy__block--last {
  margin-bottom: 15px;
}

.privacy__ttl {
  margin-bottom: 11px;
  font-size: 17px;
  font-weight: 600;
}

.privacy__txt {
  font-size: 13px;
  line-height: 1.7;
}

.privacy__txt a {
  color: #fff;
}

.privacy__foot {
  font-size: 13px;
}

.information_box {
  margin-bottom: 15px;
  padding: 15px 18px;
  background: #292929;
  font-size: 13px;
  line-height: 1.7;
}

.information_box .strong {
  font-weight: 700;
}

.information_box a {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .privacy__block {
    margin-bottom: 57px;
  }
  .privacy__block--last {
    margin-bottom: 20px;
  }
  .privacy__ttl {
    margin-bottom: 24px;
    font-size: 25px;
  }
  .privacy__txt {
    font-size: 16px;
  }
  .privacy__foot {
    font-size: 16px;
  }
  .information_box {
    margin-bottom: 40px;
    padding: 23px 32px;
    font-size: 16px;
  }
}

/* THANKSページ */
.thanks__block {
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
}

.thanks__ttl {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
}

.thanks__txt {
  display: table;
  font-size: 12px;
  margin: 0 auto 20px;
  line-height: 1.7;
}

.thanks__txt2 {
  padding: 20px;
  font-size: 12px;
  margin: 0 auto 30px;
  line-height: 1.7;
  text-align: left;
  border: 1px solid #555;
}

@media screen and (min-width: 768px) {
  .thanks__block {
    margin: 100px auto;
  }
  .thanks__ttl {
    margin-bottom: 40px;
    font-size: 32px;
  }
  .thanks__txt {
    margin: 0 auto 40px;
    font-size: 16px;
    text-align: center;
  }
  .thanks__txt2 {
    padding: 30px;
    margin: 0 auto 60px;
    font-size: 14px;
  }
}
